@import "../website-v2/_variables.less";

.hero-section {
  padding: var(--space-80) var(--space-0);
  display: flex;
  flex-direction: column;
  gap: var(--space-24);

  &:has(.video) {
    padding-bottom: var(--space-0);

    .hero-section__asset {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -2rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 51rem;
        max-height: 29.625rem;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background: var(--hero-section-highlight);
        filter: blur(50px);
      }
    }
  }

  &__heading {
    color: var(--text-primary);
    font-size: 3.5rem;
    line-height: 4.5rem;
    font-weight: 900;
    margin: 0;
    text-align: center;
    text-wrap: balance;

    & + .hero-section__asset {
      margin-top: var(--space-56);
    }

    strong {
      background: var(--text-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__subheading {
    font-size: var(--h5);
    line-height: 169%;
    color: var(--text-tertiary);
    font-weight: 400;
    margin: 0;
    text-align: center;
    text-wrap: balance;
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-16);
    padding: var(--space-8);
    margin-bottom: var(--space-32);
  }

  // Layout context
  .layout--level-1 & {
    padding-top: var(--space-0);
  }
}

.flex-container.stripe:has(.hero-section) {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--hero-section-linear-gradient);
    max-height: 17.57531rem;
    height: 100%;
    width: 100%;
  }
}

// Theme
.theme {
  &--orange {
    .flex-container.stripe:has(.hero-section) {
      // Video box shadow overflow
      overflow: hidden;

      &::before {
        // No gradient for orange theme
        display: none;
      }
    }

    &:has(.video) {
      padding-bottom: var(--space-0);
  
      .hero-section__asset {
        position: relative;

        .video {
          position: relative;
          z-index: 1;
        }

        &::before {
          max-width: 77.5rem;
          max-height: 36.125rem;
        }
  
        &::after {
          content: '';
          position: absolute;
          margin: 0 auto;
          max-height: 35.625rem;
          height: 100%;
          border-radius: 50%;
          background: radial-gradient(147.99% 147.96% at 50% -1.55%, rgba(239, 108, 52, 0.90) 0%, #CC3CFF 73.48%, rgba(10, 35, 54, 0.00) 100%);
          filter: blur(50px);
          top: 10%;
          left: -5rem;
          right: -5rem;
          width: auto;
        }
      }
    }
  }
}